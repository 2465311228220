import dynamic from "next/dynamic";
import { renderLineBreaks } from "../../../utils";
import { NextImage } from "..";
import {
  CardItemGridWrapper,
  CardItemWrapper,
  CardItemInfoWrapper,
  TitleWrapper,
  DescriptionWrapper,
  CardButton,
} from "./styles";
import EditorJSContent from "../../editor-js-content";

const Link = dynamic(() => import("../link/link"));
const CardHeader = dynamic(() => import("../card-header/card-header"));

const Title = ({ children, titleWithLink, ...cardProps }) => {
  return (
    <TitleWrapper {...cardProps}>
      {titleWithLink ? (
        <h3>
          <Link {...titleWithLink} />
        </h3>
      ) : (
        <h3>{renderLineBreaks(children)}</h3>
      )}
    </TitleWrapper>
  );
};

const Description = ({ children, richText, ...cardProps }) => {
  return (
    <DescriptionWrapper {...cardProps}>
      {richText ? (
        <EditorJSContent content={richText} />
      ) : (
        <p>{renderLineBreaks(children)}</p>
      )}
    </DescriptionWrapper>
  );
};

export const CardItem = ({
  id,
  titleWithLink,
  title,
  description,
  descriptionRichText,
  image,
  featured,
  imageFirst,
  bigImage,
  link,
  header,
  hideOnMobile,
  showImageOnMobile,
  imageFit
}) => {
  const cardProps = {
    imageFirst,
    bigImage,
    featured,
    header,
    hideOnMobile,
    showImageOnMobile,
    imageFit
  };

  return (
    <CardItemGridWrapper id={id} {...cardProps}>
      {header && <CardHeader {...header} />}
      <CardItemWrapper {...cardProps}>
        <CardItemInfoWrapper {...cardProps}>
          <Title titleWithLink={titleWithLink} {...cardProps}>
            {title}
          </Title>
          <Description richText={descriptionRichText} {...cardProps}>
            {description}
          </Description>
          {link && (
            <CardButton>
              <Link type="buttonPrimary" {...link} />
            </CardButton>
          )}
        </CardItemInfoWrapper>
        {!featured && image && <NextImage media={image} />}
      </CardItemWrapper>
    </CardItemGridWrapper>
  );
};

export default CardItem;
